<template>
  <section>
    <ul class="container">
      <li class="card table-list">
        <h3>{{$t('accounts')}}</h3>
        <div class="table-responsive">
          <table v-if="accounts&&accounts.length>0">
            <thead>
              <tr>
                <th>{{$t('address')}}</th>
                <th>{{$t('nonce')}}</th>
                <th>{{$t('balance')}}</th>
                <th>{{$t('active')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in accounts" :key="item.id">
                <td>
                  <router-link :to="'/account/'+item.attributes.address">{{item.attributes.address}}</router-link>
                </td>
                <td>{{item.attributes.nonce?item.attributes.nonce:0}}</td>
                <td>{{webUtil.formatNumber(item.attributes.balance_total,tokenDecimals)}} {{tokenSymbol}}</td>
                <td class="text-green" v-if="!item.attributes.is_reaped">True</td>
                <td class="text-orange" v-else>False</td>
              </tr>
            </tbody>
          </table>
          <div class="null" v-else>{{$t('noInformation')}}</div>
          <loading :loading="accountsLoading"></loading>
        </div>
        <!-- 翻页 -->
        <pagination-view :total="total" :page="page" @emitPage="getAccounts"></pagination-view>
      </li>
    </ul>
  </section>
</template>

<script>
import loading from "base/loading";
import paginationView from "base/pagination";
export default {
  data() {
    return {
      accounts: null,
      accountsLoading: true,
      total: 0,
      page: 1,
    };
  },
  created() {
    this.getTotal();
    this.getAccounts(1);
  },
  methods: {
    getTotal() {
      this.axios
        .get(this.domain + "networkstats/latest")
        .then(res => {
          this.total = res.data.data.attributes.total_accounts;
        })
        .catch(() => {
          console.log("Get txs failed");
        });
    },
    getAccounts(page) {
      this.page = page;
      this.accountsLoading = true;
      this.axios
        .get(this.domain + "account?page[size]=20&page[number]=" + page)
        .then(res => {
          this.accounts = res.data.data;
          this.accountsLoading = false;
        })
        .catch(() => {
          this.accounts = null;
          this.accountsLoading = false;
          console.log("Get accounts failed");
        });
    }
  },
  components: {
    paginationView,
    loading
  }
};
</script>

<style scoped>
</style>
